.current-cell-backdrop[data-v-7e442713] {
  border: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: 0;
  z-index: 80;
}
.current-cell-content[data-v-7e442713] {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background-color: lightblue;
  padding: 8px 4px;
  z-index: 120;
}
.editor-cell[data-v-7e442713] {
  border: 0px solid black !important;
  padding: 0;
}
.editor[data-v-7e442713] {
  border-radius: 2px;
  background-color: lightblue;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 4px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 4px;
  z-index: 100;
}
.editor .panel[data-v-7e442713] {
  margin: 0;
}
.event-week[data-v-7e442713] {
  cursor: pointer;
}
.day-cell[data-v-7e442713] {
  display: table-cell;
  white-space: normal;
}
