.inline[data-v-6d955a28] {
  display: inline;
}
.active[data-v-6d955a28] {
  font-weight: bold;
}
.margins[data-v-6d955a28] {
  margin-left: 4px;
  margin-right: 4px;
}
label[data-v-6d955a28] {
  font-weight: normal;
}
