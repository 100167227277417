#upload-container[data-v-7cbfc830] {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}
#upload-container > .upload[data-v-7cbfc830] {
  width: 190px;
  margin: 11px;
  cursor: not-allowed;
}
#upload-container > .upload.is_draggable[data-v-7cbfc830] {
  cursor: move;
}
#upload-container > .upload.ghost[data-v-7cbfc830] {
  background: yellow;
}
