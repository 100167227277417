h4.page-header[data-v-1ce5a157] {
  margin-top: 0;
  margin-bottom: 5px;
}
.panel-offer-difference[data-v-1ce5a157]:not(:last-child) {
  margin-bottom: 50px;
}
.panel-offer-difference .panel-offer-difference-row[data-v-1ce5a157]:not(:last-child) {
  margin-bottom: 15px;
}
.panel-offer-difference .panel-offer-difference-row > .panel-offer-difference-row-header[data-v-1ce5a157] {
  padding: 2px 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.panel-offer-difference .panel-offer-difference-row > .panel-offer-difference-row-description[data-v-1ce5a157] {
  padding: 10px 5px;
}
