[data-theme="light"] ul[data-v-162f088a] {
  list-style: none;
  width: 240px;
  xflex: 1;
  padding: 0;
  margin: 0;
}
[data-theme="light"] .opti-menu-item[data-v-162f088a] {
  height: 100%;
  min-height: 48px;
  position: relative;
  cursor: pointer;
  display: block;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  margin-left: 8px;
  margin-bottom: 2px;
  padding-left: 16px;
}
[data-theme="light"] .opti-menu-item.small[data-v-162f088a] {
  min-height: 36px;
}
[data-theme="light"] .opti-menu-item.small .opti-menu-item__header[data-v-162f088a] {
  min-height: 36px;
}
[data-theme="light"] .opti-menu-item__separator[data-v-162f088a] {
  height: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 4px;
  margin-left: 20px;
}
[data-theme="light"] .opti-menu-item.no-submenu[data-v-162f088a] {
  border-radius: 8px;
  margin-right: 8px;
  padding-right: 16px;
}
[data-theme="light"] .opti-menu-item.raw-item[data-v-162f088a] {
  min-height: calc(48px * 4);
  padding: 10px;
}
[data-theme="light"] .opti-menu-item .right[data-v-162f088a] {
  margin-left: auto;
  order: 2;
  width: 30px;
  height: 30px;
}
[data-theme="light"] .opti-menu-item .right.no-margin[data-v-162f088a] {
  margin-left: unset;
}
[data-theme="light"] .opti-menu-item .right-label[data-v-162f088a] {
  margin-left: auto;
  order: 2;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
[data-theme="light"] .opti-menu-item__header[data-v-162f088a] {
  height: 100%;
  min-height: 48px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: unset;
  text-decoration: none;
}
[data-theme="light"] .opti-menu-item__header > span[data-v-162f088a] {
  display: block;
}
[data-theme="light"] .opti-menu-item__icon[data-v-162f088a] {
  display: inline-block;
  padding: 8px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-right: 8px;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
}
[data-theme="light"] .opti-menu-item__dot[data-v-162f088a] {
  display: block;
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #f05050;
}
[data-theme="light"] .opti-menu-item .icon-empty[data-v-162f088a] {
  background-color: unset;
}
[data-theme="dark"] ul[data-v-162f088a] {
  list-style: none;
  width: 240px;
  xflex: 1;
  padding: 0;
  margin: 0;
}
[data-theme="dark"] .opti-menu-item[data-v-162f088a] {
  height: 100%;
  min-height: 48px;
  position: relative;
  cursor: pointer;
  display: block;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  margin-left: 8px;
  margin-bottom: 2px;
  padding-left: 16px;
}
[data-theme="dark"] .opti-menu-item.small[data-v-162f088a] {
  min-height: 36px;
}
[data-theme="dark"] .opti-menu-item.small .opti-menu-item__header[data-v-162f088a] {
  min-height: 36px;
}
[data-theme="dark"] .opti-menu-item__separator[data-v-162f088a] {
  height: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 4px;
  margin-left: 20px;
}
[data-theme="dark"] .opti-menu-item.no-submenu[data-v-162f088a] {
  border-radius: 8px;
  margin-right: 8px;
  padding-right: 16px;
}
[data-theme="dark"] .opti-menu-item.raw-item[data-v-162f088a] {
  min-height: calc(48px * 4);
  padding: 10px;
}
[data-theme="dark"] .opti-menu-item .right[data-v-162f088a] {
  margin-left: auto;
  order: 2;
  width: 30px;
  height: 30px;
}
[data-theme="dark"] .opti-menu-item .right.no-margin[data-v-162f088a] {
  margin-left: unset;
}
[data-theme="dark"] .opti-menu-item .right-label[data-v-162f088a] {
  margin-left: auto;
  order: 2;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
[data-theme="dark"] .opti-menu-item__header[data-v-162f088a] {
  height: 100%;
  min-height: 48px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: unset;
  text-decoration: none;
}
[data-theme="dark"] .opti-menu-item__header > span[data-v-162f088a] {
  display: block;
}
[data-theme="dark"] .opti-menu-item__icon[data-v-162f088a] {
  display: inline-block;
  padding: 8px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-right: 8px;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
}
[data-theme="dark"] .opti-menu-item__dot[data-v-162f088a] {
  display: block;
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #f90;
}
[data-theme="dark"] .opti-menu-item .icon-empty[data-v-162f088a] {
  background-color: unset;
}
