[data-theme="light"] .filter-button[data-v-b36d0196] {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 5px;
  max-width: fit-content;
}
[data-theme="light"] .filter-button[data-v-b36d0196]:hover {
  box-shadow: 0 0 1px 1px gray;
}
[data-theme="light"] .filter-button i[data-v-b36d0196] {
  width: 25px;
  line-height: 0.3em;
  text-align: center;
}
[data-theme="light"] .filter-button label.filter-name[data-v-b36d0196] {
  flex-grow: 1;
  border: 2px solid;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 0;
  cursor: pointer;
}
[data-theme="light"] .filter-button label.filter-name.filter-option-true[data-v-b36d0196] {
  background: lightgreen;
  border-color: #002401;
}
[data-theme="light"] .filter-button label.filter-name.filter-option-true i[data-v-b36d0196],
[data-theme="light"] .filter-button label.filter-name.filter-option-true span[data-v-b36d0196] {
  color: #002401;
}
[data-theme="light"] .filter-button label.filter-name.filter-option-false[data-v-b36d0196] {
  background: lightpink;
  border-color: #440101;
}
[data-theme="light"] .filter-button label.filter-name.filter-option-false i[data-v-b36d0196],
[data-theme="light"] .filter-button label.filter-name.filter-option-false span[data-v-b36d0196] {
  color: #440101;
}
[data-theme="dark"] .filter-button[data-v-b36d0196] {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 5px;
  max-width: fit-content;
}
[data-theme="dark"] .filter-button[data-v-b36d0196]:hover {
  box-shadow: 0 0 1px 1px gray;
}
[data-theme="dark"] .filter-button i[data-v-b36d0196] {
  width: 25px;
  line-height: 0.3em;
  text-align: center;
}
[data-theme="dark"] .filter-button label.filter-name[data-v-b36d0196] {
  flex-grow: 1;
  border: 2px solid;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 0;
  cursor: pointer;
}
[data-theme="dark"] .filter-button label.filter-name.filter-option-true[data-v-b36d0196] {
  background: lightgreen;
  border-color: #002401;
}
[data-theme="dark"] .filter-button label.filter-name.filter-option-true i[data-v-b36d0196],
[data-theme="dark"] .filter-button label.filter-name.filter-option-true span[data-v-b36d0196] {
  color: #002401;
}
[data-theme="dark"] .filter-button label.filter-name.filter-option-false[data-v-b36d0196] {
  background: lightpink;
  border-color: #440101;
}
[data-theme="dark"] .filter-button label.filter-name.filter-option-false i[data-v-b36d0196],
[data-theme="dark"] .filter-button label.filter-name.filter-option-false span[data-v-b36d0196] {
  color: #440101;
}
