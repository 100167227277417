[data-theme="light"] .search-result-item[data-v-5132157e] {
  display: flex;
  min-width: 400px;
  height: 100px;
  margin: 4px;
  flex-basis: 0;
  flex-grow: 1;
  background-color: #fff;
  border: 1px solid silver;
  cursor: pointer;
  padding: 1px;
  border-radius: 3px;
}
@media only screen and (max-width: 767px) {
[data-theme="light"] .search-result-item[data-v-5132157e] {
    min-width: unset;
}
}
[data-theme="light"] .search-result-item__info[data-v-5132157e] {
  width: 120px;
  color: #2b2b2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
[data-theme="light"] .search-result-item__info__type[data-v-5132157e] {
  text-align: center;
}
[data-theme="light"] .search-result-item__info__id[data-v-5132157e] {
  font-size: 1.4em;
  font-weight: bold;
}
[data-theme="light"] .search-result-item__body[data-v-5132157e] {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  padding: 4px;
  text-shadow: white -1px 0px 1px, white 0px 1px 1px, white 1px 0px 1px, white 0px -1px 1px;
}
[data-theme="light"] .search-result-item__body__name[data-v-5132157e] {
  font-size: 1.4em;
  font-weight: bold;
}
[data-theme="light"] .search-result-item__body__icon[data-v-5132157e] {
  position: absolute;
  top: 0;
  right: 4px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909FA7;
  opacity: 0.2;
}
[data-theme="light"] .search-result-item__body__date[data-v-5132157e] {
  position: absolute;
  right: 4px;
  bottom: 4px;
  font-size: 0.8em;
  color: #909FA7;
}
[data-theme="dark"] .search-result-item[data-v-5132157e] {
  display: flex;
  min-width: 400px;
  height: 100px;
  margin: 4px;
  flex-basis: 0;
  flex-grow: 1;
  background-color: #373737;
  border: 1px solid silver;
  cursor: pointer;
  padding: 1px;
  border-radius: 3px;
}
@media only screen and (max-width: 767px) {
[data-theme="dark"] .search-result-item[data-v-5132157e] {
    min-width: unset;
}
}
[data-theme="dark"] .search-result-item__info[data-v-5132157e] {
  width: 120px;
  color: #2b2b2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
[data-theme="dark"] .search-result-item__info__type[data-v-5132157e] {
  text-align: center;
}
[data-theme="dark"] .search-result-item__info__id[data-v-5132157e] {
  font-size: 1.4em;
  font-weight: bold;
}
[data-theme="dark"] .search-result-item__body[data-v-5132157e] {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  padding: 4px;
  text-shadow: white -1px 0px 1px, white 0px 1px 1px, white 1px 0px 1px, white 0px -1px 1px;
}
[data-theme="dark"] .search-result-item__body__name[data-v-5132157e] {
  font-size: 1.4em;
  font-weight: bold;
}
[data-theme="dark"] .search-result-item__body__icon[data-v-5132157e] {
  position: absolute;
  top: 0;
  right: 4px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909FA7;
  opacity: 0.2;
}
[data-theme="dark"] .search-result-item__body__date[data-v-5132157e] {
  position: absolute;
  right: 4px;
  bottom: 4px;
  font-size: 0.8em;
  color: #909FA7;
}
