.layoutInfo[data-v-9d7ebf24] {
  position: absolute;
  top: 4px;
  right: 4px;
  text-align: right;
}
.layoutInfo > div > div > a.link[data-v-9d7ebf24] {
  color: #000;
  text-shadow: 0px 0px 8px #fff;
}
