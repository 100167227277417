[data-theme="light"] .space-around[data-v-27aa21bb] {
  display: flex;
  justify-content: space-around;
}
[data-theme="light"] .footer-buttons[data-v-27aa21bb] {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
[data-theme="light"] .footer-buttons[data-v-27aa21bb] {
    flex-direction: column;
    align-items: center;
}
[data-theme="light"] .footer-buttons[data-v-27aa21bb] > :not(:last-child) {
    margin-bottom: 10px;
}
}
[data-theme="dark"] .space-around[data-v-27aa21bb] {
  display: flex;
  justify-content: space-around;
}
[data-theme="dark"] .footer-buttons[data-v-27aa21bb] {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
[data-theme="dark"] .footer-buttons[data-v-27aa21bb] {
    flex-direction: column;
    align-items: center;
}
[data-theme="dark"] .footer-buttons[data-v-27aa21bb] > :not(:last-child) {
    margin-bottom: 10px;
}
}
