[data-theme="light"] table#mail-table-header[data-v-03c35ef0] {
  margin-top: 10px;
}
[data-theme="light"] table#mail-table-header > tbody > tr > th[data-v-03c35ef0] {
  padding: 5px 20px 5px 0;
  text-align: right;
}
[data-theme="light"] #body[data-v-03c35ef0] {
  width: 100%;
  height: calc(100dvh - 250px);
  overflow: auto;
}
[data-theme="light"] #body.panel-multi[data-v-03c35ef0] {
  height: calc(100dvh - 190px);
}
[data-theme="light"] #body .panel.widget[data-v-03c35ef0] {
  margin-bottom: 0;
  border-radius: 0;
}
[data-theme="light"] #body .panel.widget.bg-warning[data-v-03c35ef0] {
  color: #000 !important;
}
[data-theme="light"] #body .panel.widget.bg-warning i.fa[data-v-03c35ef0],
[data-theme="light"] #body .panel.widget.bg-warning .link[data-v-03c35ef0] {
  color: #000 !important;
  font-weight: 400;
}
[data-theme="light"] #body .panel.widget.bg-warning ul[data-v-03c35ef0] {
  list-style-type: square;
}
[data-theme="light"] #body > .panel-mail[data-v-03c35ef0] {
  width: 100%;
}
[data-theme="light"] #body > .panel-mail > .panel-mail-header[data-v-03c35ef0] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #23b7e5;
  color: #000;
  padding: 5px 10px;
  cursor: pointer;
}
[data-theme="light"] #body > .panel-mail > .panel-mail-header > table > tbody > tr > th[data-v-03c35ef0] {
  padding-right: 5px;
}
[data-theme="light"] #body > .panel-mail > iframe[data-v-03c35ef0] {
  background: #fff;
  width: 100%;
}
[data-theme="light"] #body > .panel-mail[data-v-03c35ef0]:not(:last-child) {
  border-bottom: 1px solid #eee;
}
@media only screen and (max-width: 991px) {
[data-theme="light"] #body[data-v-03c35ef0] {
    height: calc(100dvh - 315px);
}
[data-theme="light"] #body.panel-multi[data-v-03c35ef0] {
    height: calc(100dvh - 230px);
}
}
[data-theme="dark"] table#mail-table-header[data-v-03c35ef0] {
  margin-top: 10px;
}
[data-theme="dark"] table#mail-table-header > tbody > tr > th[data-v-03c35ef0] {
  padding: 5px 20px 5px 0;
  text-align: right;
}
[data-theme="dark"] #body[data-v-03c35ef0] {
  width: 100%;
  height: calc(100dvh - 250px);
  overflow: auto;
}
[data-theme="dark"] #body.panel-multi[data-v-03c35ef0] {
  height: calc(100dvh - 190px);
}
[data-theme="dark"] #body .panel.widget[data-v-03c35ef0] {
  margin-bottom: 0;
  border-radius: 0;
}
[data-theme="dark"] #body .panel.widget.bg-warning[data-v-03c35ef0] {
  color: #000 !important;
}
[data-theme="dark"] #body .panel.widget.bg-warning i.fa[data-v-03c35ef0],
[data-theme="dark"] #body .panel.widget.bg-warning .link[data-v-03c35ef0] {
  color: #000 !important;
  font-weight: 400;
}
[data-theme="dark"] #body .panel.widget.bg-warning ul[data-v-03c35ef0] {
  list-style-type: square;
}
[data-theme="dark"] #body > .panel-mail[data-v-03c35ef0] {
  width: 100%;
}
[data-theme="dark"] #body > .panel-mail > .panel-mail-header[data-v-03c35ef0] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #23b7e5;
  color: #000;
  padding: 5px 10px;
  cursor: pointer;
}
[data-theme="dark"] #body > .panel-mail > .panel-mail-header > table > tbody > tr > th[data-v-03c35ef0] {
  padding-right: 5px;
}
[data-theme="dark"] #body > .panel-mail > iframe[data-v-03c35ef0] {
  background: #fff;
  width: 100%;
}
[data-theme="dark"] #body > .panel-mail[data-v-03c35ef0]:not(:last-child) {
  border-bottom: 1px solid #eee;
}
@media only screen and (max-width: 991px) {
[data-theme="dark"] #body[data-v-03c35ef0] {
    height: calc(100dvh - 315px);
}
[data-theme="dark"] #body.panel-multi[data-v-03c35ef0] {
    height: calc(100dvh - 230px);
}
}
