[data-theme="light"] .chart-tooltip[data-v-ee5b3f46] {
  background-color: #f5f7fa;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="light"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="light"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table {
  border: 0;
}
[data-theme="light"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="light"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="light"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="light"] table > tbody > tr.tr-task-range > td[data-v-ee5b3f46] {
  border-top: 0;
}
[data-theme="light"] table > tbody > tr.tr-task-range > td > div[data-v-ee5b3f46] {
  margin-bottom: 5px;
}
[data-theme="light"] table > tbody > tr.tr-task-range > td > div > span[data-v-ee5b3f46] {
  display: inline-block;
}
[data-theme="light"] table > tbody > tr.tr-task-range > td > div > span[data-v-ee5b3f46]:nth-child(1) {
  width: 40px;
}
[data-theme="light"] table > tbody > tr.tr-task-range > td > div > span[data-v-ee5b3f46]:nth-child(2) {
  width: 150px;
}
[data-theme="light"] .panel > .panel-footer[data-v-ee5b3f46] {
  padding-top: 0;
  padding-bottom: 0;
}
[data-theme="light"] .panel > .panel-footer > .row > div[class^='col-'][data-v-ee5b3f46] {
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-theme="light"] .star-info[data-v-ee5b3f46]:after {
  color: #23b7e5 !important;
}
[data-theme="dark"] .chart-tooltip[data-v-ee5b3f46] {
  background-color: #373737;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="dark"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="dark"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table {
  border: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="dark"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-ee5b3f46] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="dark"] table > tbody > tr.tr-task-range > td[data-v-ee5b3f46] {
  border-top: 0;
}
[data-theme="dark"] table > tbody > tr.tr-task-range > td > div[data-v-ee5b3f46] {
  margin-bottom: 5px;
}
[data-theme="dark"] table > tbody > tr.tr-task-range > td > div > span[data-v-ee5b3f46] {
  display: inline-block;
}
[data-theme="dark"] table > tbody > tr.tr-task-range > td > div > span[data-v-ee5b3f46]:nth-child(1) {
  width: 40px;
}
[data-theme="dark"] table > tbody > tr.tr-task-range > td > div > span[data-v-ee5b3f46]:nth-child(2) {
  width: 150px;
}
[data-theme="dark"] .panel > .panel-footer[data-v-ee5b3f46] {
  padding-top: 0;
  padding-bottom: 0;
}
[data-theme="dark"] .panel > .panel-footer > .row > div[class^='col-'][data-v-ee5b3f46] {
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-theme="dark"] .star-info[data-v-ee5b3f46]:after {
  color: #23b7e5 !important;
}
