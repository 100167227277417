
[data-v-b136e028] .multiselect--disabled {
  opacity: 1;
}
[data-v-b136e028] .multiselect__select {
  background: inherit;
}
.form-control[disabled][data-v-b136e028] {
  background: inherit !important;
}
a.btn-add[data-v-b136e028] {
  font-weight: 700;
  color: limegreen;
  margin-left: 15px;
  cursor: pointer;
}
a.btn-add.disabled[data-v-b136e028] {
  opacity: 0.5;
  color: grey;
  cursor: default;
}
.constructions[data-v-b136e028] {
  padding-top: 10px;
}
.constructions .form-control[data-v-b136e028] {
  height: 40px;
}
.constructions[data-v-b136e028]:not(:last-child) {
  margin-bottom: 15px;
}
.row > .col-sm-4 > .section[data-v-b136e028] {
  border: 1px solid grey;
  border-radius: 3px;
  margin-bottom: 1em;
}
.row > .col-sm-4 > .section > .section-title[data-v-b136e028] {
  text-align: center;
  font-weight: 700;
  padding-top: 5px;
}
.row > .col-sm-4 > .section > .label-line[data-v-b136e028] {
  display: block;
  padding: 0 10px;
}
.row > .col-sm-4 > .section > .label-line label[data-v-b136e028] {
  margin-bottom: 0px;
}
.row > .col-sm-4 > .section > .label-line > .pull-right label[data-v-b136e028]:not(:last-child) {
  margin-right: 10px;
}
.text-center > label[data-v-b136e028] {
  display: block;
}
.row.row-link-padding[data-v-b136e028] {
  padding: 10px 0;
}
