
[data-v-0e15ed3f] .modal-container {
  max-width: 98%;
}
[data-v-0e15ed3f] .modal-container > .modal-body {
  text-align: center;
}
[data-v-0e15ed3f] .modal-container > .modal-body > img {
  max-width: 100%;
}
