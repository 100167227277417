.margin[data-v-ecdedb94] {
  margin-bottom: 15px;
}
.fa-stack[data-v-ecdedb94] {
  color: #fff;
}
.fa-stack[data-v-ecdedb94]:hover,
.fa-stack[data-v-ecdedb94]:focus {
  color: #fff;
}
.fa-stack > .fa-stack-2x[data-v-ecdedb94] {
  color: royalblue;
  -webkit-text-stroke: 1px powderblue;
  text-stroke: 1px powderblue;
}
.fa-stack > .fa-stack-2x.fa-rotate-45[data-v-ecdedb94] {
  transform: rotate(45deg);
}
