.panel.widget[data-v-4c818600] {
  margin-bottom: 50px;
}
.panel.widget ul[data-v-4c818600] {
  list-style-type: square;
}
.panel.widget ul > li[data-v-4c818600] {
  padding: 3px 0;
}
.panel.widget ul > li > a[data-v-4c818600] {
  color: inherit;
}
.panel.widget #settings-offer-session-state-info[data-v-4c818600] {
  opacity: 0.85;
}
