
[data-v-77b78720] .modal-body {
  background: #fff;
}
i.fa-close[data-v-77b78720] {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  color: grey;
  z-index: 9999;
}
#product-name[data-v-77b78720] {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 26px;
  font-weight: 700;
  margin-top: 10px;
  color: #000;
  text-shadow: 0 0 5px #fff;
  width: 100%;
  text-align: center;
}
#product-image[data-v-77b78720] {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#product-image > img[data-v-77b78720] {
  width: 100%;
}
#products-thumbnails[data-v-77b78720] {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
#products-thumbnails > img.product-thumbnail[data-v-77b78720] {
  display: inline-block;
  width: 75px;
  margin: 10px;
  outline: 2px solid lightgrey;
  border-radius: 3px;
  cursor: pointer;
}
#products-thumbnails > img.product-thumbnail[data-v-77b78720]:hover {
  outline: 2px solid orange;
}
#products-thumbnails > img.product-thumbnail.selected[data-v-77b78720] {
  outline: 2px solid darkslategrey;
}
