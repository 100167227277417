.opti-search-panel[data-v-39d64f68] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.opti-search-panel__input[data-v-39d64f68] {
  margin: 60px;
  margin-top: 80px;
  width: 60%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
}
.opti-search-panel__input[data-v-39d64f68] .form-control {
  font-size: 20px;
  line-height: 2;
  text-align: center;
  height: 48px;
}
.opti-search-panel__results[data-v-39d64f68] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: auto;
  height: 100%;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.opti-search-panel__overlimit[data-v-39d64f68] {
  text-align: center;
}
.opti-search-panel__loading[data-v-39d64f68] {
  margin: 60px;
}
.opti-search-panel__recent[data-v-39d64f68] {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
.opti-search-panel__recent_panel[data-v-39d64f68] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(100, 100, 100, 0.3);
  padding: 10px;
  margin: 10px;
}
