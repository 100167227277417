.grid-brand-product[data-v-40710ec6] {
  cursor: not-allowed;
}
.grid-brand-product.is_draggable[data-v-40710ec6] {
  cursor: move;
}
.grid-brand-product[data-v-40710ec6]:active .product-tooltip,
.grid-brand-product[data-v-40710ec6]:active .product-link:not(:hover) {
  display: none !important;
}
.grid-brand-product.ghost[data-v-40710ec6] {
  background: #ff9900ff;
  color: #000;
}
.toggle-disabled[data-v-40710ec6] {
  color: #909fa7;
  opacity: 0.3;
  cursor: default !important;
}
