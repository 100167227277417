.multiselect[data-v-9fb4778a],
.multiselect[data-v-9fb4778a] .multiselect__input,
.multiselect[data-v-9fb4778a] .multiselect__single {
  font-size: 14px;
}
.multiselect[data-v-9fb4778a] .simple {
  width: 350px;
  right: 0;
}
.multiselect[data-v-9fb4778a] .multiselect__tags {
  cursor: pointer;
}
.muted[data-v-9fb4778a] {
  opacity: 0.5;
}
