.multiselect[data-v-3bae62a0],
.multiselect[data-v-3bae62a0] .multiselect__input,
.multiselect[data-v-3bae62a0] .multiselect__single {
  font-size: 14px;
}
.multiselect[data-v-3bae62a0] .simple {
  width: 350px;
  right: 0;
}
.multiselect[data-v-3bae62a0] .multiselect__tags {
  cursor: pointer;
}
.muted[data-v-3bae62a0] {
  opacity: 0.5;
}
