[data-theme="light"] .footer-buttons[data-v-164687c4] {
  display: flex;
  justify-content: space-between;
}
[data-theme="light"] .footer-buttons > div[data-v-164687c4],
[data-theme="light"] .footer-buttons button[data-v-164687c4] {
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
[data-theme="light"] .footer-buttons[data-v-164687c4] {
    flex-direction: column;
    align-items: center;
}
}
[data-theme="light"] #opti-catalog-offer-header[data-v-164687c4] .table {
  margin-bottom: 0 !important;
}
[data-theme="light"] .pb[data-v-164687c4] {
  padding-bottom: 5px;
}
[data-theme="dark"] .footer-buttons[data-v-164687c4] {
  display: flex;
  justify-content: space-between;
}
[data-theme="dark"] .footer-buttons > div[data-v-164687c4],
[data-theme="dark"] .footer-buttons button[data-v-164687c4] {
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
[data-theme="dark"] .footer-buttons[data-v-164687c4] {
    flex-direction: column;
    align-items: center;
}
}
[data-theme="dark"] #opti-catalog-offer-header[data-v-164687c4] .table {
  margin-bottom: 0 !important;
}
[data-theme="dark"] .pb[data-v-164687c4] {
  padding-bottom: 5px;
}
