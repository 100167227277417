.multiselect[data-v-51a7bc38],
.multiselect[data-v-51a7bc38] .multiselect__input,
.multiselect[data-v-51a7bc38] .multiselect__single {
  font-size: 14px;
}
.muted[data-v-51a7bc38] {
  opacity: 0.5;
}
.small[data-v-51a7bc38] {
  font-size: 10px;
}
