[data-theme="light"] .chart-tooltip[data-v-0ca637f0] {
  background-color: #f5f7fa;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="light"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="light"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table {
  border: 0;
}
[data-theme="light"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="light"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="light"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="light"] .percent-positive[data-v-0ca637f0] {
  color: #27c24c;
}
[data-theme="light"] .percent-positive > span[data-v-0ca637f0]:before {
  content: '▲';
}
[data-theme="light"] .percent-negative[data-v-0ca637f0] {
  color: #f05050;
}
[data-theme="light"] .percent-negative > span[data-v-0ca637f0]:before {
  content: '▼';
}
[data-theme="light"] .no-brake[data-v-0ca637f0] {
  word-break: keep-all;
  text-wrap: none;
}
[data-theme="light"] p.arrow[data-v-0ca637f0] {
  font-size: 20px;
}
[data-theme="dark"] .chart-tooltip[data-v-0ca637f0] {
  background-color: #373737;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="dark"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="dark"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table {
  border: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="dark"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-0ca637f0] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="dark"] .percent-positive[data-v-0ca637f0] {
  color: #375980;
}
[data-theme="dark"] .percent-positive > span[data-v-0ca637f0]:before {
  content: '▲';
}
[data-theme="dark"] .percent-negative[data-v-0ca637f0] {
  color: #375980;
}
[data-theme="dark"] .percent-negative > span[data-v-0ca637f0]:before {
  content: '▼';
}
[data-theme="dark"] .no-brake[data-v-0ca637f0] {
  word-break: keep-all;
  text-wrap: none;
}
[data-theme="dark"] p.arrow[data-v-0ca637f0] {
  font-size: 20px;
}
