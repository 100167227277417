.panel[data-v-9879ecd2] {
  margin-bottom: 0;
  border-radius: 0;
}
#canvas-draw[data-v-9879ecd2] {
  background: bisque;
  padding: 10px 0;
}
#canvas-draw[data-v-9879ecd2] canvas {
  display: block;
  margin: 0 auto;
  width: 90%;
  cursor: crosshair;
}
