.opti-select-change-active-department[data-v-5229379a] {
  display: inline-block;
}
.opti-select-change-active-department .flex[data-v-5229379a] {
  display: flex;
}
.opti-select-change-active-department .flex div[data-v-5229379a] {
  flex: 1;
}
.opti-select-change-active-department .flex .opti-select-department[data-v-5229379a] {
  display: inline-block;
}
