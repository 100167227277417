[data-theme="light"] img[data-v-ea7add2e] {
  width: 100%;
  margin-bottom: 20px;
}
[data-theme="light"] .section[data-v-ea7add2e] {
  text-align: center;
}
[data-theme="light"] #offer-help-header[data-v-ea7add2e] {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}
[data-theme="light"] #offer-help-header > #offer-help-header-title[data-v-ea7add2e] {
  min-width: 170px;
}
[data-theme="light"] #offer-help-header > #offer-help-header-title > .option[data-v-ea7add2e] {
  font-size: 22px;
  line-height: 1em;
  font-weight: 700;
}
[data-theme="light"] #offer-help-header > .offer-help-header-navi[data-v-ea7add2e]:not(.offer-help-header-navi-disabled) {
  cursor: pointer;
}
[data-theme="light"] #offer-help-header > .offer-help-header-navi.offer-help-header-navi-disabled[data-v-ea7add2e] {
  opacity: 0.2;
}
[data-theme="light"] .offer-help-description[data-v-ea7add2e] {
  margin-bottom: 20px;
}
[data-theme="light"] form#offer-help-attributes[data-v-ea7add2e] {
  margin-bottom: 15px;
}
[data-theme="light"] form#offer-help-attributes label[data-v-ea7add2e] {
  font-weight: 400;
}
[data-theme="light"] form#offer-help-attributes label.section[data-v-ea7add2e] {
  display: block;
  margin-bottom: 5px;
}
[data-theme="light"] form#offer-help-attributes > div[data-v-ea7add2e] {
  overflow-wrap: anywhere;
}
[data-theme="light"] .thumbnail > img[data-v-ea7add2e] {
  margin-bottom: 0px;
}
[data-theme="light"] .thumbnail > .caption[data-v-ea7add2e] {
  text-align: center;
  color: #000;
}
[data-theme="dark"] img[data-v-ea7add2e] {
  width: 100%;
  margin-bottom: 20px;
}
[data-theme="dark"] .section[data-v-ea7add2e] {
  text-align: center;
}
[data-theme="dark"] #offer-help-header[data-v-ea7add2e] {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}
[data-theme="dark"] #offer-help-header > #offer-help-header-title[data-v-ea7add2e] {
  min-width: 170px;
}
[data-theme="dark"] #offer-help-header > #offer-help-header-title > .option[data-v-ea7add2e] {
  font-size: 22px;
  line-height: 1em;
  font-weight: 700;
}
[data-theme="dark"] #offer-help-header > .offer-help-header-navi[data-v-ea7add2e]:not(.offer-help-header-navi-disabled) {
  cursor: pointer;
}
[data-theme="dark"] #offer-help-header > .offer-help-header-navi.offer-help-header-navi-disabled[data-v-ea7add2e] {
  opacity: 0.2;
}
[data-theme="dark"] .offer-help-description[data-v-ea7add2e] {
  margin-bottom: 20px;
}
[data-theme="dark"] form#offer-help-attributes[data-v-ea7add2e] {
  margin-bottom: 15px;
}
[data-theme="dark"] form#offer-help-attributes label[data-v-ea7add2e] {
  font-weight: 400;
}
[data-theme="dark"] form#offer-help-attributes label.section[data-v-ea7add2e] {
  display: block;
  margin-bottom: 5px;
}
[data-theme="dark"] form#offer-help-attributes > div[data-v-ea7add2e] {
  overflow-wrap: anywhere;
}
[data-theme="dark"] .thumbnail > img[data-v-ea7add2e] {
  margin-bottom: 0px;
}
[data-theme="dark"] .thumbnail > .caption[data-v-ea7add2e] {
  text-align: center;
  color: #000;
}
