#lens-container[data-v-043357c0] {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
}
#lens-container > .lens-info[data-v-043357c0] {
  margin: 5px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}
#lens-container > .lens-info > .lens-info-label[data-v-043357c0] {
  color: #fff;
  padding: 0 15px 0 8px;
}
#lens-container > .lens-info > .lens-info-label > .lens-info-label-name[data-v-043357c0] {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
#lens-container > .lens-info > .lens-info-label > .lens-info-label-price[data-v-043357c0] {
  float: right;
  padding-top: 4px;
}
#lens-container > .lens-info > .lens-info-label > .lens-info-label-close[data-v-043357c0] {
  position: absolute;
  top: 5px;
  right: -60px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  cursor: pointer;
}
#lens-container > .lens-info > .lens-info-compare-label[data-v-043357c0] {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  font-weight: 700;
  color: #fff;
}
#lens-container > .lens-info > .lens-info-compare-label > i[data-v-043357c0] {
  margin-top: 3px;
}
#lens-container > .lens-info > .lens-info-compare > div[data-v-043357c0] {
  display: inline-block;
}
#lens-container > .lens-info > .lens-info-compare > i.fa-lg[data-v-043357c0] {
  padding: 8px 8px 3px;
  opacity: 0.5;
}
