.dropzone[data-v-62ea5ce8] {
  background: inherit;
  border: 1px solid #cfdbe2;
  border-radius: 5px;
  min-height: initial;
  padding: 0;
}
.dropzone.disabled[data-v-62ea5ce8] {
  opacity: 0.3;
}
.dropzone[data-v-62ea5ce8] .dz-preview {
  margin: 0;
  border-radius: 5px;
  width: 100%;
}
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-image {
  background: rgba(33, 150, 243, 0.8);
  border-radius: 5px;
}
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-image > img {
  width: 100% !important;
  object-fit: inherit !important;
}
.dropzone[data-v-62ea5ce8] .dz-preview:hover > .dz-details {
  background-color: transparent;
  font-size: 20px;
}
.dropzone[data-v-62ea5ce8] .dz-preview:hover .dz-image > img {
  transform: scale(1.2, 1.2);
  filter: blur(5px);
}
.dropzone[data-v-62ea5ce8] .dz-preview:hover > .dz-image > img {
  border-radius: 5px;
}
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-details {
  padding-top: 15px;
}
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-details > .dz-size,
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-details .dz-filename {
  text-align: center;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-details > .dz-size > span,
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-details .dz-filename > span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone[data-v-62ea5ce8] .dz-preview > .dz-details > .dz-size {
  font-size: 18px;
  margin-bottom: 5px;
}
.dropzone[data-v-62ea5ce8] .dz-message {
  margin: 1em 0;
}
.dropzone[data-v-62ea5ce8] .dz-error-message {
  top: 10px;
  width: 90%;
  margin: 0 5%;
}
.dropzone[data-v-62ea5ce8] .dz-remove {
  padding: 0;
  left: 50%;
  margin-left: -65px;
  border: none;
  cursor: default;
}
.dropzone[data-v-62ea5ce8] .dz-remove:hover {
  text-decoration: none;
}
.dropzone[data-v-62ea5ce8] .dz-remove > button {
  cursor: pointer;
}
.dropzone[data-v-62ea5ce8] .dz-remove > button > span {
  cursor: pointer;
}
.dropzone[data-v-62ea5ce8] .dz-remove > button > span > i {
  cursor: pointer;
}
.dropzone[data-v-62ea5ce8] .dz-remove > button > i {
  cursor: pointer;
}
.dropzone[data-v-62ea5ce8] .dz-remove > button.btn-upload-preview {
  margin-left: 10px;
}
