.chart-tooltip[data-v-06eb1662] {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
}
.chart-tooltip__header[data-v-06eb1662] {
  padding: 0.5rem;
}
.chart-tooltip__body[data-v-06eb1662] {
  padding: 0.5rem;
}
.chart-tooltip__footer[data-v-06eb1662] {
  padding: 0.5rem;
}
