.product-icon[data-v-d4b4468e] {
  position: absolute !important;
  z-index: 1 !important;
  opacity: 0.7 !important;
  top: 0 !important;
  margin-top: 30px !important;
}
.product-name-price[data-v-d4b4468e] {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;
}
.opti-product-info-small .product-name-price[data-v-d4b4468e] {
  font-size: 0.75em;
}
.product-mark[data-v-d4b4468e] {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -18px;
}
.product-mark > i.fa-check[data-v-d4b4468e] {
  color: #27c24c;
  text-shadow: 0 0 2px #000;
}
.product-mark > i.fa-heart[data-v-d4b4468e] {
  color: red;
  text-shadow: 0 0 2px #fff;
}
