img.optional[data-v-d414a596] {
  width: 80px;
  margin: 0 10px 10px 0;
  opacity: 0.2;
}
img.optional.selected[data-v-d414a596] {
  opacity: 1;
}
.row.row-link-padding[data-v-d414a596] {
  padding: 10px 0;
}
