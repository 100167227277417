[data-theme="light"] .btn-left-right[data-v-80cdd49a] {
  position: absolute;
  padding: 9px 9px 9px 7px;
  top: 42%;
  z-index: 9999;
}
[data-theme="light"] .btn-left-right#btn-left[data-v-80cdd49a] {
  left: -8px;
}
[data-theme="light"] .btn-left-right#btn-right[data-v-80cdd49a] {
  right: -8px;
}
@media only screen and (min-width: 1500px) {
[data-theme="light"] .btn-left-right#btn-left[data-v-80cdd49a] {
    left: -60px;
}
[data-theme="light"] .btn-left-right#btn-right[data-v-80cdd49a] {
    right: -60px;
}
}
[data-theme="dark"] .btn-left-right[data-v-80cdd49a] {
  position: absolute;
  padding: 9px 9px 9px 7px;
  top: 42%;
  z-index: 9999;
}
[data-theme="dark"] .btn-left-right#btn-left[data-v-80cdd49a] {
  left: -8px;
}
[data-theme="dark"] .btn-left-right#btn-right[data-v-80cdd49a] {
  right: -8px;
}
@media only screen and (min-width: 1500px) {
[data-theme="dark"] .btn-left-right#btn-left[data-v-80cdd49a] {
    left: -60px;
}
[data-theme="dark"] .btn-left-right#btn-right[data-v-80cdd49a] {
    right: -60px;
}
}
