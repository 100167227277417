.margin[data-v-9c440658] {
  margin-bottom: 15px;
}
.opti-report-offers-rate[data-v-9c440658] {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: #fff;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack[data-v-9c440658],
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack[data-v-9c440658] {
  color: #fff;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack[data-v-9c440658]:hover,
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack[data-v-9c440658]:hover,
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack[data-v-9c440658]:focus,
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack[data-v-9c440658]:focus {
  color: inherit;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack.no_rate[data-v-9c440658],
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack.no_rate[data-v-9c440658] {
  opacity: 0.6;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack.loading > .fa-stack-2x[data-v-9c440658],
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack.loading > .fa-stack-2x[data-v-9c440658] {
  color: royalblue;
  -webkit-text-stroke: 1px powderblue;
  text-stroke: 1px powderblue;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack.up > .fa-stack-2x[data-v-9c440658],
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack.up > .fa-stack-2x[data-v-9c440658] {
  color: forestgreen;
  -webkit-text-stroke: 1px greenyellow;
  text-stroke: 1px greenyellow;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack.warning > .fa-stack-2x[data-v-9c440658],
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack.warning > .fa-stack-2x[data-v-9c440658] {
  color: darkorange;
  -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack.warning > .fa-stack-1x[data-v-9c440658],
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack.warning > .fa-stack-1x[data-v-9c440658] {
  color: #000;
}
.opti-report-offers-rate > .opti-report-offers-rate-params > span > .fa-stack.down > .fa-stack-2x[data-v-9c440658],
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack.down > .fa-stack-2x[data-v-9c440658] {
  color: indianred;
  -webkit-text-stroke: 1px darkred;
  text-stroke: 1px darkred;
}
.opti-report-offers-rate > .opti-report-offers-rate-sum-params > .fa-stack > .fa-stack-2x[data-v-9c440658] {
  transform: rotate(45deg);
}
