[data-theme="light"] ul[data-v-248d4cd8] {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
[data-theme="light"] .opti-left-menu[data-v-248d4cd8] {
  position: fixed;
  top: 0;
  left: -15px;
  bottom: 0;
  z-index: 1000;
  width: auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.5);
}
@media only screen and (min-width: 768px) {
[data-theme="light"] .opti-left-menu[data-v-248d4cd8] {
    display: flex;
    flex-direction: row;
}
}
@media only screen and (max-width: 1024px) {
[data-theme="light"] .opti-left-menu__small[data-v-248d4cd8] {
    left: -15px;
    width: 65px;
}
}
[data-theme="light"] .opti-left-menu__content[data-v-248d4cd8] {
  width: 240px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
[data-theme="light"] .opti-left-menu__content > ul[data-v-248d4cd8] {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
[data-theme="light"] .opti-left-menu__level[data-v-248d4cd8] {
  padding-top: 8px;
  width: 240px;
  z-index: 1020;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 5px 0px 20px -5px rgba(0, 0, 0, 0.5);
}
[data-theme="light"] .opti-left-menu__level > ul[data-v-248d4cd8] {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}
[data-theme="light"] .opti-left-menu__level > ul > li[data-v-248d4cd8] {
  flex-grow: 1;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
}
[data-theme="light"] .opti-left-menu__level > ul > li[data-v-248d4cd8]:last-child {
  border-bottom: 0;
}
[data-theme="light"] .opti-left-menu__level_bottom[data-v-248d4cd8] {
  justify-content: flex-end;
}
[data-theme="light"] .opti-left-menu__level0[data-v-248d4cd8] {
  left: 240px;
}
[data-theme="light"] .opti-left-menu__level1[data-v-248d4cd8] {
  left: 480px;
}
[data-theme="light"] .opti-left-menu__level2[data-v-248d4cd8] {
  left: 720px;
}
[data-theme="light"] .opti-left-menu__nav-back[data-v-248d4cd8] {
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 767px) {
[data-theme="light"] .opti-left-menu[data-v-248d4cd8] {
    width: 0;
    padding-top: 80px;
    padding-bottom: 148px;
}
[data-theme="light"] .opti-left-menu__opened[data-v-248d4cd8] {
    left: 0;
    width: 100%;
}
[data-theme="light"] .opti-left-menu__content[data-v-248d4cd8] {
    width: 100%;
}
[data-theme="light"] .opti-left-menu__header[data-v-248d4cd8] {
    position: relative;
    text-align: center;
    padding-bottom: 48px;
}
[data-theme="light"] .opti-left-menu__header > .opti-select-change-active-department[data-v-248d4cd8] {
    width: 80%;
}
[data-theme="light"] .opti-left-menu__level[data-v-248d4cd8] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: unset;
}
[data-theme="light"] .opti-left-menu__level > ul[data-v-248d4cd8] {
    display: block;
    height: unset;
    padding-top: 100px;
    padding-bottom: 100px;
}
[data-theme="light"] .opti-left-menu__level > ul > .opti-menu-item[data-v-248d4cd8] {
    height: unset;
}
[data-theme="light"] .opti-left-menu__level0[data-v-248d4cd8],
  [data-theme="light"] .opti-left-menu__level1[data-v-248d4cd8],
  [data-theme="light"] .opti-left-menu__level2[data-v-248d4cd8] {
    left: 0;
}
}
[data-theme="dark"] ul[data-v-248d4cd8] {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
[data-theme="dark"] .opti-left-menu[data-v-248d4cd8] {
  position: fixed;
  top: 0;
  left: -15px;
  bottom: 0;
  z-index: 1000;
  width: auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.5);
}
@media only screen and (min-width: 768px) {
[data-theme="dark"] .opti-left-menu[data-v-248d4cd8] {
    display: flex;
    flex-direction: row;
}
}
@media only screen and (max-width: 1024px) {
[data-theme="dark"] .opti-left-menu__small[data-v-248d4cd8] {
    left: -15px;
    width: 65px;
}
}
[data-theme="dark"] .opti-left-menu__content[data-v-248d4cd8] {
  width: 240px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
[data-theme="dark"] .opti-left-menu__content > ul[data-v-248d4cd8] {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
[data-theme="dark"] .opti-left-menu__level[data-v-248d4cd8] {
  padding-top: 8px;
  width: 240px;
  z-index: 1020;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 5px 0px 20px -5px rgba(0, 0, 0, 0.5);
}
[data-theme="dark"] .opti-left-menu__level > ul[data-v-248d4cd8] {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}
[data-theme="dark"] .opti-left-menu__level > ul > li[data-v-248d4cd8] {
  flex-grow: 1;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
}
[data-theme="dark"] .opti-left-menu__level > ul > li[data-v-248d4cd8]:last-child {
  border-bottom: 0;
}
[data-theme="dark"] .opti-left-menu__level_bottom[data-v-248d4cd8] {
  justify-content: flex-end;
}
[data-theme="dark"] .opti-left-menu__level0[data-v-248d4cd8] {
  left: 240px;
}
[data-theme="dark"] .opti-left-menu__level1[data-v-248d4cd8] {
  left: 480px;
}
[data-theme="dark"] .opti-left-menu__level2[data-v-248d4cd8] {
  left: 720px;
}
[data-theme="dark"] .opti-left-menu__nav-back[data-v-248d4cd8] {
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 767px) {
[data-theme="dark"] .opti-left-menu[data-v-248d4cd8] {
    width: 0;
    padding-top: 80px;
    padding-bottom: 148px;
}
[data-theme="dark"] .opti-left-menu__opened[data-v-248d4cd8] {
    left: 0;
    width: 100%;
}
[data-theme="dark"] .opti-left-menu__content[data-v-248d4cd8] {
    width: 100%;
}
[data-theme="dark"] .opti-left-menu__header[data-v-248d4cd8] {
    position: relative;
    text-align: center;
    padding-bottom: 48px;
}
[data-theme="dark"] .opti-left-menu__header > .opti-select-change-active-department[data-v-248d4cd8] {
    width: 80%;
}
[data-theme="dark"] .opti-left-menu__level[data-v-248d4cd8] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: unset;
}
[data-theme="dark"] .opti-left-menu__level > ul[data-v-248d4cd8] {
    display: block;
    height: unset;
    padding-top: 100px;
    padding-bottom: 100px;
}
[data-theme="dark"] .opti-left-menu__level > ul > .opti-menu-item[data-v-248d4cd8] {
    height: unset;
}
[data-theme="dark"] .opti-left-menu__level0[data-v-248d4cd8],
  [data-theme="dark"] .opti-left-menu__level1[data-v-248d4cd8],
  [data-theme="dark"] .opti-left-menu__level2[data-v-248d4cd8] {
    left: 0;
}
}
