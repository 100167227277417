tr.finger[data-v-65a0c426] {
  cursor: pointer;
}
tr > td.difference[data-v-65a0c426] {
  background: #000;
  color: #fff;
}
tr > td.difference > a.link[data-v-65a0c426] {
  color: #fff;
}
