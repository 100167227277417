.grid-brand[data-v-5fd62d96] {
  cursor: move;
}
.grid-brand.ghost > .content[data-v-5fd62d96] {
  box-shadow: 0 0 0 6px red !important;
}
ul.dropdown-menu[data-v-5fd62d96] {
  cursor: default;
}
ul.dropdown-menu > li[data-v-5fd62d96]:not([class="divider"]) {
  cursor: pointer !important;
}
ul.dropdown-menu > li:not([class="divider"]) > a > .form-control[data-v-5fd62d96] {
  background: transparent;
  border: none;
  padding: 0;
  color: inherit;
}
