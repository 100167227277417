[data-theme="light"] .fill-box[data-v-33ca4834] {
  display: flex;
}
[data-theme="light"] .fill-grow[data-v-33ca4834] {
  flex: 1;
  padding-right: 20px;
}
[data-theme="light"] .relative[data-v-33ca4834] {
  position: relative;
}
[data-theme="light"] .chart-tooltip[data-v-33ca4834] {
  background-color: #f5f7fa;
  border-radius: 0.5rem;
  xfont-size: 0.8em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="light"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="light"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table {
  border: 0;
}
[data-theme="light"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="light"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="light"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="light"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__body .line-legend {
  display: inline-block;
  margin-right: 4px;
  border: 2px solid transparent;
  width: 20px;
  height: 20px;
}
[data-theme="light"] .no-brake[data-v-33ca4834] {
  word-break: keep-all;
  text-wrap: none;
}
[data-theme="dark"] .fill-box[data-v-33ca4834] {
  display: flex;
}
[data-theme="dark"] .fill-grow[data-v-33ca4834] {
  flex: 1;
  padding-right: 20px;
}
[data-theme="dark"] .relative[data-v-33ca4834] {
  position: relative;
}
[data-theme="dark"] .chart-tooltip[data-v-33ca4834] {
  background-color: #373737;
  border-radius: 0.5rem;
  xfont-size: 0.8em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="dark"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="dark"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table {
  border: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="dark"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-33ca4834] .chart-tooltip__body .line-legend {
  display: inline-block;
  margin-right: 4px;
  border: 2px solid transparent;
  width: 20px;
  height: 20px;
}
[data-theme="dark"] .no-brake[data-v-33ca4834] {
  word-break: keep-all;
  text-wrap: none;
}
