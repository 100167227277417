.opti-flex-box[data-v-5e05bddc] {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.flex-content[data-v-5e05bddc] {
  overflow: auto;
  height: 100%;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
}
.shadow-box[data-v-5e05bddc] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 200;
}
