
[data-v-e5877030] .modal-container {
  max-width: 98%;
}
[data-v-e5877030] .modal-container > .modal-body {
  text-align: center;
}
[data-v-e5877030] .modal-container > .modal-body > img {
  max-width: 100%;
}
