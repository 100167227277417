
[data-v-e2a8f636] .modal-container {
  max-width: 550px;
}
[data-v-e2a8f636] .modal-container > .modal-body {
  padding: 0;
}
[data-v-e2a8f636] .modal-container > .modal-body .table {
  margin-bottom: 0;
}
