.multiselect[data-v-76f96d81],
.multiselect[data-v-76f96d81] .multiselect__input,
.multiselect[data-v-76f96d81] .multiselect__single {
  font-size: 14px;
}
.muted[data-v-76f96d81] {
  opacity: 0.5;
}
.small[data-v-76f96d81] {
  font-size: 10px;
}
