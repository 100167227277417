[data-theme="light"] .task-offer-switch[data-v-54e8179c] {
  margin-bottom: 5px;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-img[data-v-54e8179c] {
  text-align: center;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-img > img[data-v-54e8179c] {
  width: 50%;
  opacity: 0.65;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-info[data-v-54e8179c] {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 5px;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c] {
  padding: 3px;
  opacity: 0.65;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c]:first-child {
  text-align: center;
  font-weight: 700;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-info > div:not(:first-child).selected[data-v-54e8179c] {
  border: 3px solid darkorange;
  margin-top: -2px;
  opacity: 1;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c]:not(:first-child):hover:not(.selected) {
  cursor: pointer;
  opacity: 1;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c]:not(:last-child) {
  border-bottom: 1px solid #aaa;
}
[data-theme="light"] .task-offer-switch > .task-offer-switch-info > div > div[data-v-54e8179c] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
[data-theme="light"] .task-offer-switch.selected > .task-offer-switch-img > img[data-v-54e8179c],
[data-theme="light"] .task-offer-switch.selected > .task-offer-switch-info > div[data-v-54e8179c]:first-child {
  opacity: 1;
}
[data-theme="dark"] .task-offer-switch[data-v-54e8179c] {
  margin-bottom: 5px;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-img[data-v-54e8179c] {
  text-align: center;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-img > img[data-v-54e8179c] {
  width: 50%;
  opacity: 0.65;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-info[data-v-54e8179c] {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 5px;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c] {
  padding: 3px;
  opacity: 0.65;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c]:first-child {
  text-align: center;
  font-weight: 700;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-info > div:not(:first-child).selected[data-v-54e8179c] {
  border: 3px solid darkorange;
  margin-top: -2px;
  opacity: 1;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c]:not(:first-child):hover:not(.selected) {
  cursor: pointer;
  opacity: 1;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-info > div[data-v-54e8179c]:not(:last-child) {
  border-bottom: 1px solid #aaa;
}
[data-theme="dark"] .task-offer-switch > .task-offer-switch-info > div > div[data-v-54e8179c] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
[data-theme="dark"] .task-offer-switch.selected > .task-offer-switch-img > img[data-v-54e8179c],
[data-theme="dark"] .task-offer-switch.selected > .task-offer-switch-info > div[data-v-54e8179c]:first-child {
  opacity: 1;
}
