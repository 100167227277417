[data-theme="light"] table[data-v-17791ab3] {
  margin-bottom: 15px !important;
}
[data-theme="light"] table > tbody > tr > th[data-v-17791ab3],
[data-theme="light"] table > tbody > tr > td[data-v-17791ab3] {
  border: none !important;
}
[data-theme="light"] table > tbody > tr > th[data-v-17791ab3] {
  width: 70px;
  height: 50px;
  vertical-align: middle !important;
  padding: 0 0 0 8px !important;
}
[data-theme="light"] table > tbody > tr > th > small[data-v-17791ab3] {
  display: block;
}
[data-theme="light"] table > tbody > tr > td > .text-muted[data-v-17791ab3],
[data-theme="light"] table > tbody > tr > td > .warn[data-v-17791ab3] {
  margin-top: 5px;
}
[data-theme="light"] .label-offer[data-v-17791ab3] {
  margin: 5px 10px 5px 0 !important;
}
[data-theme="light"] .label-offer.disabled[data-v-17791ab3] {
  opacity: 0.6;
}
@media (max-width: 767px) {
[data-theme="light"] .col-xs-12 img[data-v-17791ab3] {
    width: 100%;
}
}
[data-theme="dark"] table[data-v-17791ab3] {
  margin-bottom: 15px !important;
}
[data-theme="dark"] table > tbody > tr > th[data-v-17791ab3],
[data-theme="dark"] table > tbody > tr > td[data-v-17791ab3] {
  border: none !important;
}
[data-theme="dark"] table > tbody > tr > th[data-v-17791ab3] {
  width: 70px;
  height: 50px;
  vertical-align: middle !important;
  padding: 0 0 0 8px !important;
}
[data-theme="dark"] table > tbody > tr > th > small[data-v-17791ab3] {
  display: block;
}
[data-theme="dark"] table > tbody > tr > td > .text-muted[data-v-17791ab3],
[data-theme="dark"] table > tbody > tr > td > .warn[data-v-17791ab3] {
  margin-top: 5px;
}
[data-theme="dark"] .label-offer[data-v-17791ab3] {
  margin: 5px 10px 5px 0 !important;
}
[data-theme="dark"] .label-offer.disabled[data-v-17791ab3] {
  opacity: 0.6;
}
@media (max-width: 767px) {
[data-theme="dark"] .col-xs-12 img[data-v-17791ab3] {
    width: 100%;
}
}
