
[data-v-a5919a58] .multiselect {
  float: left;
}
[data-v-a5919a58] .multiselect .multiselect__tags {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.phoneNumber[data-v-a5919a58] {
  height: 40px;
}
