@keyframes slide-data-v-8e7bcf50 {
0% {
    transform: translateX(-100%);
}
100% {
    transform: translateX(100%);
}
}
[data-theme="light"] .dimmer[data-v-8e7bcf50] {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
[data-theme="light"] .backdrop[data-v-8e7bcf50] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #515253;
  opacity: 0.3;
  overflow: hidden;
}
[data-theme="light"] .backdrop[data-v-8e7bcf50]:after {
  content: '';
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide-data-v-8e7bcf50 2s infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
}
[data-theme="light"] .loader[data-v-8e7bcf50] {
  color: #515253;
  opacity: 0.9;
  text-shadow: 1px 0 0 #f5f7fa, -1px 0 0 #f5f7fa, 0 1px 0 #f5f7fa, 0 -1px 0 #f5f7fa, 1px 1px #f5f7fa, -1px -1px 0 #f5f7fa, 1px -1px 0 #f5f7fa, -1px 1px 0 #f5f7fa;
}
@keyframes slide-data-v-8e7bcf50 {
0% {
    transform: translateX(-100%);
}
100% {
    transform: translateX(100%);
}
}
[data-theme="dark"] .dimmer[data-v-8e7bcf50] {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
[data-theme="dark"] .backdrop[data-v-8e7bcf50] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.3;
  overflow: hidden;
}
[data-theme="dark"] .backdrop[data-v-8e7bcf50]:after {
  content: '';
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide-data-v-8e7bcf50 2s infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
}
[data-theme="dark"] .loader[data-v-8e7bcf50] {
  color: #fff;
  opacity: 0.9;
  text-shadow: 1px 0 0 #373737, -1px 0 0 #373737, 0 1px 0 #373737, 0 -1px 0 #373737, 1px 1px #373737, -1px -1px 0 #373737, 1px -1px 0 #373737, -1px 1px 0 #373737;
}
