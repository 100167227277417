[data-theme="light"] .multiselect[data-v-3f6af914],
[data-theme="light"] .multiselect[data-v-3f6af914] .multiselect__input,
[data-theme="light"] .multiselect[data-v-3f6af914] .multiselect__single {
  font-size: 14px;
}
[data-theme="light"] .multiselect[data-v-3f6af914] .simple {
  width: 350px;
  right: 0;
}
@media only screen and (max-width: 767px) {
[data-theme="light"] .multiselect[data-v-3f6af914] .simple {
    width: unset;
    right: unset;
}
}
[data-theme="light"] .multiselect[data-v-3f6af914] .multiselect__tags {
  cursor: pointer;
}
[data-theme="light"] .muted[data-v-3f6af914] {
  opacity: 0.5;
}
[data-theme="dark"] .multiselect[data-v-3f6af914],
[data-theme="dark"] .multiselect[data-v-3f6af914] .multiselect__input,
[data-theme="dark"] .multiselect[data-v-3f6af914] .multiselect__single {
  font-size: 14px;
}
[data-theme="dark"] .multiselect[data-v-3f6af914] .simple {
  width: 350px;
  right: 0;
}
@media only screen and (max-width: 767px) {
[data-theme="dark"] .multiselect[data-v-3f6af914] .simple {
    width: unset;
    right: unset;
}
}
[data-theme="dark"] .multiselect[data-v-3f6af914] .multiselect__tags {
  cursor: pointer;
}
[data-theme="dark"] .muted[data-v-3f6af914] {
  opacity: 0.5;
}
