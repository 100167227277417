
[data-v-230518ac] .input-group-date {
  width: 140px;
}
[data-v-230518ac] .input-group-date > input#date-expected {
  height: 25px;
  padding: 0 7px;
}
[data-v-230518ac] .input-group-date > .input-group-btn > .btn {
  height: 25px;
  padding-top: 2px;
}
[data-v-230518ac] .input-group-date > .input-group-btn > .btn > .fa-calendar {
  font-size: 14px;
}
