#navigation-budget-edit[data-v-0a581d8c] {
  display: flex;
  justify-content: center;
}
#navigation-budget-edit > #date[data-v-0a581d8c] {
  display: inline-block;
  width: 160px;
}
#navigation-budget-edit .input-group[data-v-0a581d8c] {
  width: 220px;
}
#navigation-budget-edit .input-group#month > .input-group-addon[data-v-0a581d8c] {
  padding: 0;
  border: 0;
}
#navigation-budget-edit #btn-accept[data-v-0a581d8c] {
  margin-left: 15px;
}
