td > .bg-separator[data-v-77b04184] {
  display: block;
  margin-bottom: 15px;
  border: 2px solid transparent;
}
td > .bg-separator > a.text-center[data-v-77b04184] {
  display: block;
  position: relative;
  color: inherit;
  text-decoration: none !important;
}
td > .bg-separator.is_cancelled:not(:hover) > a.text-center > .main-info[data-v-77b04184] {
  opacity: 0.2;
}
td > .bg-separator.is_cancelled:not(:hover) > a.text-center .position-absolute[data-v-77b04184] {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
td > .bg-separator.is_cancelled:not(:hover) > a.text-center .position-absolute > .label-cancelled > i.fa.fa-remove[data-v-77b04184] {
  display: block;
  font-size: 40px;
}
td > .bg-separator.is_cancelled:not(:hover) > a.text-center .position-absolute > .label-cancelled > span[data-v-77b04184] {
  font-size: 20px;
  font-weight: 700;
}
td > .bg-separator > .users[data-v-77b04184] {
  max-height: 0;
  transition: max-height 0.75s ease;
  overflow: hidden;
}
td > .bg-separator > .users > .month-event-user[data-v-77b04184] {
  cursor: pointer;
}
td > .bg-separator > .users > .month-event-user[data-v-77b04184]:first-child {
  margin-top: 10px !important;
}
td > .bg-separator > .users > .month-event-user.cancelled[data-v-77b04184] {
  opacity: 0.35 !important;
  text-decoration: line-through;
}
td > .bg-separator[data-v-77b04184]:hover {
  border: 2px solid #aaa;
}
td > .bg-separator:hover > .users[data-v-77b04184] {
  max-height: 1000px;
  transition: max-height 2s ease;
}
