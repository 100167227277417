[data-theme="light"] .opti-search-menu[data-v-85cce2b6] {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  min-height: 48px;
  z-index: 1050;
  box-shadow: -20px -5px 10px 5px rgba(0, 0, 0, 0.5);
}
[data-theme="light"] .opti-search-menu__button[data-v-85cce2b6] {
  min-height: 48px;
  min-width: 80px;
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
[data-theme="light"] .opti-search-menu__topic[data-v-85cce2b6] {
  position: absolute;
  left: -60px;
  top: 0;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 15;
  font-size: 1.2em;
}
[data-theme="light"] .opti-search-menu__content[data-v-85cce2b6] {
  display: flex;
  flex-direction: row;
  padding: 2px;
  z-index: 120;
  position: relative;
  align-items: center;
  align-content: center;
}
[data-theme="light"] .opti-search-menu__slope[data-v-85cce2b6] {
  transform: skew(45deg);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -48px;
  z-index: 10;
  box-shadow: 0px -5px 10px 5px rgba(0, 0, 0, 0.5);
}
[data-theme="light"] .opti-search-menu__user[data-v-85cce2b6] {
  cursor: default;
  font-size: 0.9em;
  text-align: center;
  font-weight: bold;
  width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
[data-theme="light"] .opti-search-menu__user__info[data-v-85cce2b6] {
  font-size: 0.8em;
}
@media only screen and (max-width: 767px) {
[data-theme="light"] .opti-search-menu__department[data-v-85cce2b6] {
    display: none;
}
}
[data-theme="dark"] .opti-search-menu[data-v-85cce2b6] {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  min-height: 48px;
  z-index: 1050;
  box-shadow: -20px -5px 10px 5px rgba(0, 0, 0, 0.5);
}
[data-theme="dark"] .opti-search-menu__button[data-v-85cce2b6] {
  min-height: 48px;
  min-width: 80px;
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
[data-theme="dark"] .opti-search-menu__topic[data-v-85cce2b6] {
  position: absolute;
  left: -60px;
  top: 0;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 15;
  font-size: 1.2em;
}
[data-theme="dark"] .opti-search-menu__content[data-v-85cce2b6] {
  display: flex;
  flex-direction: row;
  padding: 2px;
  z-index: 120;
  position: relative;
  align-items: center;
  align-content: center;
}
[data-theme="dark"] .opti-search-menu__slope[data-v-85cce2b6] {
  transform: skew(45deg);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -48px;
  z-index: 10;
  box-shadow: 0px -5px 10px 5px rgba(0, 0, 0, 0.5);
}
[data-theme="dark"] .opti-search-menu__user[data-v-85cce2b6] {
  cursor: default;
  font-size: 0.9em;
  text-align: center;
  font-weight: bold;
  width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
[data-theme="dark"] .opti-search-menu__user__info[data-v-85cce2b6] {
  font-size: 0.8em;
}
@media only screen and (max-width: 767px) {
[data-theme="dark"] .opti-search-menu__department[data-v-85cce2b6] {
    display: none;
}
}
