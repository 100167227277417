#navigation-label[data-v-57ba109a] {
  display: flex;
  justify-content: space-around;
}
#navigation-label > div[data-v-57ba109a]:nth-child(2) {
  cursor: pointer;
}
#navigation-label > .btn[data-v-57ba109a] {
  display: flex;
  align-items: center;
}
