.departments[data-v-d2955d1c] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
}
.departments .border[data-v-d2955d1c] {
  border: double red;
}
.departments .pie[data-v-d2955d1c] {
  width: 200px;
}
