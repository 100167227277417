.switch-index[data-v-6cbd528e] {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.switch-col[data-v-6cbd528e] {
  margin-top: 25%;
}
.aligned-row[data-v-6cbd528e] {
  display: flex;
  position: relative;
}
