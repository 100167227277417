.header[data-v-5a59b12c] {
  padding-bottom: 5px;
  font-size: 18px;
}
label[data-v-5a59b12c] {
  margin: 0;
}
.training:not(:last-child) > .media-box[data-v-5a59b12c] {
  border-bottom: 1px solid #000;
}
.training > .media-box[data-v-5a59b12c] {
  padding: 10px;
  position: relative;
}
.training > .media-box > .media-box-body.opacity[data-v-5a59b12c] {
  opacity: 0.35;
}
.training > .media-box > .btn-toggle[data-v-5a59b12c] {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 10px;
  background: #232735;
  color: whitesmoke;
  cursor: pointer;
  font-size: 12px;
}
