#measurement-app-department[data-v-74a53c7c] {
  text-align: center;
  padding: 15px 5px 0;
}
ul[data-v-74a53c7c] {
  list-style-type: none;
  padding-left: 10px;
}
ul > li[data-v-74a53c7c]:not(:last-child) {
  margin-bottom: 15px;
}
small.warn[data-v-74a53c7c] {
  display: block;
  margin: 0 5px 5px;
}
