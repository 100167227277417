[data-theme="light"] .show-animation[data-v-8aaeca0a] {
  animation-name: show-animation-data-v-8aaeca0a;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 7;
}
@keyframes show-animation-data-v-8aaeca0a {
0% {
    transform: scale(1);
}
50% {
    transform: scale(1.2);
}
100% {
    transform: scale(1);
}
}
[data-theme="dark"] .show-animation[data-v-8aaeca0a] {
  animation-name: show-animation-data-v-8aaeca0a;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 7;
}
@keyframes show-animation-data-v-8aaeca0a {
0% {
    transform: scale(1);
}
50% {
    transform: scale(1.2);
}
100% {
    transform: scale(1);
}
}
