.count-missing[data-v-3d507636] {
  font-size: 18px;
}
.panel-body[data-v-3d507636] {
  padding: 15px;
  font-size: 16px;
}
.panel-body table tr:first-child th[data-v-3d507636] {
  padding-top: 20px;
}
.panel-body table tr td[data-v-3d507636] {
  padding: 15px 10px;
}
