[data-theme="light"] #padding-top-bottom[data-v-eb9d1ffc] {
  padding-top: 20px;
  padding-bottom: 20px;
}
[data-theme="light"] .padding-top-bottom[data-v-eb9d1ffc] {
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-theme="light"] i.fa.fa-heart[data-v-eb9d1ffc] {
  color: red;
  text-shadow: 0 0 5px #fff;
}
[data-theme="dark"] #padding-top-bottom[data-v-eb9d1ffc] {
  padding-top: 20px;
  padding-bottom: 20px;
}
[data-theme="dark"] .padding-top-bottom[data-v-eb9d1ffc] {
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-theme="dark"] i.fa.fa-heart[data-v-eb9d1ffc] {
  color: red;
  text-shadow: 0 0 5px #fff;
}
