.panel-body[data-v-27e1b72c] {
  padding: 0 15px;
}
.panel-body .form-group-nested[data-v-27e1b72c] {
  padding-top: 15px;
  margin-bottom: 0;
}
.panel-body .form-group-nested .priority-inline[data-v-27e1b72c] {
  display: inline-block;
  width: 50%;
}
