
[data-v-6f18b197] .modal-container {
  width: 450px;
}
[data-v-6f18b197] .modal-container .panel {
  border: none;
}
[data-v-6f18b197] .modal-container .panel > .panel-body {
  padding: 0;
}
