
[data-v-39e7860d] .col-lg-6 {
  margin-bottom: 10px;
}
.empty[data-v-39e7860d] {
  cursor: pointer;
  text-align: center;
  padding: 5px 0;
}
.table-preview[data-v-39e7860d] {
  cursor: pointer;
}
.table-preview[data-v-39e7860d] table th,
.table-preview[data-v-39e7860d] table td {
  padding: 2px !important;
  font-size: 0.9em;
  font-weight: 400;
}
.table-preview[data-v-39e7860d] table .bg-warning td {
  cursor: pointer;
}
.table-preview[data-v-39e7860d] table > tbody > tr:not([class="bg-warning"]) {
  display: none;
}
