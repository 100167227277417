.forecast[data-v-0d875cf1]::before {
  content: '*';
}
.forecast[data-v-0d875cf1],
.forecast-no-star[data-v-0d875cf1] {
  color: #909FA7;
}
.table-xs[data-v-0d875cf1] {
  font-size: 12px;
}
.table-custom-border[data-v-0d875cf1] .b-left {
  border-left: 2px solid black !important;
}
.table-custom-border[data-v-0d875cf1] .b-top {
  border-top: 2px solid black !important;
}
.table-custom-border[data-v-0d875cf1] .b-right {
  border-right: 2px solid black !important;
}
.table-custom-border[data-v-0d875cf1] .b-bottom {
  border-bottom: 2px solid black !important;
}
.info[data-v-0d875cf1] {
  text-align: center;
  font-size: 21px;
  padding: 15px;
}
