#btn-left[data-v-0c0990b8] {
  position: fixed;
  padding: 20px 30px;
  left: 5px;
  top: 45%;
}
#btn-right[data-v-0c0990b8] {
  position: fixed;
  padding: 20px 30px;
  right: 5px;
  top: 45%;
}
#btn-remove[data-v-0c0990b8] {
  position: fixed;
  right: 35px;
  top: 20px;
}
[data-v-0c0990b8] .modal-container {
  padding: 0 !important;
}
[data-v-0c0990b8] .modal-container .modal-body {
  overflow: hidden !important;
}
