#table-snapshot > thead > tr > th[data-v-b5689472] {
  position: sticky;
  position: -webkit-sticky;
  z-index: 5;
  top: 0;
}
#table-snapshot > tbody > tr > td.monospace[data-v-b5689472],
#table-snapshot > tfoot > tr > th.monospace[data-v-b5689472] {
  white-space: nowrap;
}
