.opti-select-supplier[data-v-584faf78] {
  margin-bottom: 15px;
}
.button-spacing[data-v-584faf78] {
  margin-right: 10px;
}
.button-spacing.last-button[data-v-584faf78] {
  margin-left: 20px;
}
.new-import-header[data-v-584faf78] {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}
