.pb[data-v-8b113826] {
  padding-bottom: 10px;
}
.footer-buttons[data-v-8b113826] {
  display: flex;
  justify-content: space-between;
}
.space-around[data-v-8b113826] {
  display: flex;
  justify-content: space-around;
}
