
[data-v-9ebc2bb2] .modal-container {
  max-width: 600px;
  position: relative;
}
[data-v-9ebc2bb2] .modal-container > .modal-body {
  padding: 0;
}
table[data-v-9ebc2bb2] {
  margin-bottom: 0 !important;
}
table:first-child > tbody > tr:first-child th[data-v-9ebc2bb2]:first-child {
  width: 35%;
}
table:first-child > tbody > tr:first-child th[data-v-9ebc2bb2],
table:first-child > tbody > tr:first-child td[data-v-9ebc2bb2] {
  border-top: 0;
}
