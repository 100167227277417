tr.activePrice[data-v-b39c6bb0] {
  background-color: #8dd593;
  color: #3e434c;
}
[data-v-b39c6bb0] tr.hover:hover {
  background-color: #8dd593;
  color: #3e434c;
  font-weight: bolder;
  cursor: pointer;
}
