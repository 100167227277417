
[data-v-2def0858] .modal-body {
  background: #fff;
}
i.fa-close[data-v-2def0858] {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  color: grey;
  z-index: 9999;
}
.offer-photo-slide-container[data-v-2def0858] {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.offer-photo-slide-container > img.img-photo-slide-1[data-v-2def0858] {
  max-height: 85%;
}
.offer-photo-slide-container > img.img-photo-slide-2[data-v-2def0858] {
  max-height: 75%;
}
.offer-photo-slide-container > img.img-photo-slide-3[data-v-2def0858] {
  max-height: 55%;
}
.offer-photo-slide-container > img.img-photo-slide-4[data-v-2def0858] {
  max-height: 40%;
}
