
[data-v-c0d7ee6e] .modal-container {
  max-width: 1000px;
}
[data-v-c0d7ee6e] .modal-container > .modal-body {
  padding: 0;
  overflow: unset !important;
}
[data-v-c0d7ee6e] .modal-container > .modal-body > .table-responsive {
  overflow: unset !important;
}
[data-v-c0d7ee6e] .modal-container > .modal-body > .table-responsive > .table {
  margin-bottom: 0;
}
