[data-theme="light"] .pie[data-v-f5f9a138] {
  position: relative;
  padding: 5px;
}
[data-theme="light"] .productType[data-v-f5f9a138] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  text-align: center;
  color: #515253;
}
[data-theme="light"] .productType div[data-v-f5f9a138] {
  position: relative;
  top: 6%;
  right: 1.5em;
  margin-bottom: 3px;
  text-shadow: white;
}
[data-theme="light"] .productType div.discount[data-v-f5f9a138] {
  right: 3.5em;
}
[data-theme="light"] .departmentName[data-v-f5f9a138] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  pointer-events: none;
}
[data-theme="light"] .departmentName div[data-v-f5f9a138] {
  flex: 1;
  flex-grow: 0;
  font-weight: bold;
  text-align: center;
  word-break: keep-all;
}
[data-theme="light"] p.header[data-v-f5f9a138] {
  color: black;
  font-weight: bold;
  padding: 2px 10px;
}
[data-theme="light"] .chart-tooltip[data-v-f5f9a138] {
  background-color: #f5f7fa;
  border-radius: 0.5rem;
  xfont-size: 0.8em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="light"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="light"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table {
  border: 0;
}
[data-theme="light"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="light"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="light"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="light"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__body .line-legend {
  border: 2px solid transparent;
  width: 20px;
  height: 20px;
}
[data-theme="dark"] .pie[data-v-f5f9a138] {
  position: relative;
  padding: 5px;
}
[data-theme="dark"] .productType[data-v-f5f9a138] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  text-align: center;
  color: #fff;
}
[data-theme="dark"] .productType div[data-v-f5f9a138] {
  position: relative;
  top: 6%;
  right: 1.5em;
  margin-bottom: 3px;
  text-shadow: white;
}
[data-theme="dark"] .productType div.discount[data-v-f5f9a138] {
  right: 3.5em;
}
[data-theme="dark"] .departmentName[data-v-f5f9a138] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  pointer-events: none;
}
[data-theme="dark"] .departmentName div[data-v-f5f9a138] {
  flex: 1;
  flex-grow: 0;
  font-weight: bold;
  text-align: center;
  word-break: keep-all;
}
[data-theme="dark"] p.header[data-v-f5f9a138] {
  color: black;
  font-weight: bold;
  padding: 2px 10px;
}
[data-theme="dark"] .chart-tooltip[data-v-f5f9a138] {
  background-color: #373737;
  border-radius: 0.5rem;
  xfont-size: 0.8em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="dark"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="dark"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table {
  border: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="dark"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-f5f9a138] .chart-tooltip__body .line-legend {
  border: 2px solid transparent;
  width: 20px;
  height: 20px;
}
