.opti-select-graph-lenses[data-v-66a5d61d] {
  width: 1000px;
}
.opti-select-graph-lenses[data-v-66a5d61d] .multiselect__content-wrapper {
  max-height: 85vh !important;
}
.opti-select-graph-lenses[data-v-66a5d61d] .multiselect__option--disabled {
  opacity: 0.5;
  color: inherit !important;
}
.opti-select-graph-lenses[data-v-66a5d61d] .multiselect__content-wrapper {
  display: block !important;
}
.opti-select-graph-lenses .multiselect__option.multiselect__option--highlight > ul > li[data-v-66a5d61d] {
  color: #fff;
}
