[data-theme="light"] .buttons[data-v-3420be77] {
  margin-bottom: 10px;
}
[data-theme="light"] .chart-tooltip[data-v-3420be77] {
  background-color: #f5f7fa;
  border-radius: 0.5rem;
  xfont-size: 0.8em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="light"] .chart-tooltip[data-v-3420be77] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="light"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table {
  border: 0;
}
[data-theme="light"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="light"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="light"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="light"] .no-brake[data-v-3420be77] {
  word-break: keep-all;
  text-wrap: none;
}
[data-theme="dark"] .buttons[data-v-3420be77] {
  margin-bottom: 10px;
}
[data-theme="dark"] .chart-tooltip[data-v-3420be77] {
  background-color: #373737;
  border-radius: 0.5rem;
  xfont-size: 0.8em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
[data-theme="dark"] .chart-tooltip[data-v-3420be77] .chart-tooltip__header {
  font-weight: bold;
  text-align: center;
}
[data-theme="dark"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table {
  border: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table td {
  border: 1px solid #eee;
  padding: 4px;
}
[data-theme="dark"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table td:first-child {
  border-left: 0;
}
[data-theme="dark"] .chart-tooltip[data-v-3420be77] .chart-tooltip__table td:last-child {
  border-right: 0;
}
[data-theme="dark"] .no-brake[data-v-3420be77] {
  word-break: keep-all;
  text-wrap: none;
}
