[data-theme="light"] .opti-search-recent-item[data-v-da8bbc1a] {
  position: relative;
  padding: 4px;
  margin: 4px;
  cursor: pointer;
  min-width: 400px;
}
@media only screen and (max-width: 767px) {
[data-theme="light"] .opti-search-recent-item[data-v-da8bbc1a] {
    min-width: 80%;
}
}
[data-theme="light"] .opti-search-recent-item__icon[data-v-da8bbc1a] {
  position: absolute;
  top: 0;
  left: 2px;
  bottom: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 30px;
}
[data-theme="light"] .opti-search-recent-item__body[data-v-da8bbc1a] {
  margin-left: 30px;
}
[data-theme="light"] .opti-search-recent-item__id[data-v-da8bbc1a] {
  font-weight: bold;
}
[data-theme="light"] .opti-search-recent-item__date[data-v-da8bbc1a] {
  position: absolute;
  top: 0;
  right: 4px;
  font-size: 0.8em;
  color: gray;
}
[data-theme="dark"] .opti-search-recent-item[data-v-da8bbc1a] {
  position: relative;
  padding: 4px;
  margin: 4px;
  cursor: pointer;
  min-width: 400px;
}
@media only screen and (max-width: 767px) {
[data-theme="dark"] .opti-search-recent-item[data-v-da8bbc1a] {
    min-width: 80%;
}
}
[data-theme="dark"] .opti-search-recent-item__icon[data-v-da8bbc1a] {
  position: absolute;
  top: 0;
  left: 2px;
  bottom: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 30px;
}
[data-theme="dark"] .opti-search-recent-item__body[data-v-da8bbc1a] {
  margin-left: 30px;
}
[data-theme="dark"] .opti-search-recent-item__id[data-v-da8bbc1a] {
  font-weight: bold;
}
[data-theme="dark"] .opti-search-recent-item__date[data-v-da8bbc1a] {
  position: absolute;
  top: 0;
  right: 4px;
  font-size: 0.8em;
  color: gray;
}
