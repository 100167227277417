[data-theme="light"] #graph-search-lenses[data-v-388d5319] {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background-color: rgba(0, 0, 0, 0.8);
}
[data-theme="light"] #graph-search-lenses > i.fa.fa-close[data-v-388d5319] {
  position: absolute;
  top: 5px;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
  color: #fff;
}
[data-theme="light"] #graph-search-lenses > .form-inline[data-v-388d5319] {
  width: 1000px;
  margin-bottom: 200px;
}
[data-theme="light"] #graph-search-lenses > .form-inline > .form-group > label[data-v-388d5319] {
  color: #fff;
}
[data-theme="dark"] #graph-search-lenses[data-v-388d5319] {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background-color: rgba(0, 0, 0, 0.8);
}
[data-theme="dark"] #graph-search-lenses > i.fa.fa-close[data-v-388d5319] {
  position: absolute;
  top: 5px;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
  color: #fff;
}
[data-theme="dark"] #graph-search-lenses > .form-inline[data-v-388d5319] {
  width: 1000px;
  margin-bottom: 200px;
}
[data-theme="dark"] #graph-search-lenses > .form-inline > .form-group > label[data-v-388d5319] {
  color: #fff;
}
