
[data-v-0680a2d7] .modal-container {
  max-width: 400px;
}
[data-v-0680a2d7] .modal-container .pointer {
  cursor: pointer;
}
[data-v-0680a2d7] .modal-container .outline {
  outline: 3px solid;
}
