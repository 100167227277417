.multiselect[data-v-bc10e036],
.multiselect[data-v-bc10e036] .multiselect__input,
.multiselect[data-v-bc10e036] .multiselect__single {
  font-size: 14px;
}
.muted[data-v-bc10e036] {
  opacity: 0.5;
}
.small[data-v-bc10e036] {
  font-size: 10px;
}
