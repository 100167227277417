.modal-mask[data-v-7a54880c] {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
.modal-mask > .modal-wrapper[data-v-7a54880c] {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask > .modal-wrapper > .modal-container[data-v-7a54880c] {
  margin: 0 auto;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.modal-mask > .modal-wrapper > .modal-container > .modal-header[data-v-7a54880c] {
  padding: 5px 10px;
}
.modal-mask > .modal-wrapper > .modal-container > .modal-body[data-v-7a54880c] {
  max-height: 85dvh;
  overflow: auto;
}
.modal-mask.fullscreen > .modal-wrapper > .modal-container[data-v-7a54880c] {
  height: 100dvh;
}
.modal-mask.fullscreen > .modal-wrapper > .modal-container > .modal-body[data-v-7a54880c] {
  padding: 0;
  max-height: 100dvh;
  height: 100%;
}
.modal-mask.fullscreen > .modal-wrapper > .modal-container > .modal-footer[data-v-7a54880c] {
  position: absolute;
  background: inherit;
  bottom: 0;
  width: 100%;
  z-index: 100;
}
.modal-mask.fullwidth > .modal-wrapper > .modal-container[data-v-7a54880c] {
  width: 100%;
}
