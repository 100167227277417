table[data-v-e7f8cb86] {
  margin-bottom: 0 !important;
}
table:first-child > tbody > tr > td[data-v-e7f8cb86],
table > tbody > tr:not(:first-child) > td[data-v-e7f8cb86] {
  border: none !important;
}
.product[data-v-e7f8cb86] {
  margin-bottom: 10px;
}
